<template>
  <div>
    <b-card class="invoice-preview-card">
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto">
          {{ $t("customer_information.faq.create_title") }} <br>
        </h2>
        <b-button
          variant="outline-primary"
          class="d-flex align-items-center"
          @click="$router.push({ name: 'faqs-list' })"
        >
          <i class="las la-arrow-left mr-50" />
          <span>{{ $t("button.back_to_list") }}</span>
        </b-button>
      </div>
    </b-card>

    <b-card>
      <validation-observer ref="formRules">
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('customer_information.faq.filter_label_question')"
              label-cols-md="12"
              label-for="title"
            >
              <template v-slot:label>
                <span>{{ $t("customer_information.faq.filter_label_question") }}
                  <RequiredStar />
                </span>
              </template>
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="form.title"
                  :placeholder="
                    $t(
                      'customer_information.faq.create_input_question_placeholder'
                    )
                  "
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="
                $t(
                  'customer_information.information_content.create_input_sector'
                )
              "
              label-cols-md="12"
              label-for="mots-cles"
            >
              <v-select
                id="roles"
                v-model="form.faq_section_id"
                :placeholder="
                  $t(
                    'customer_information.information_content.create_input_sector_placeholder'
                  )
                "
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :reduce="(option) => option.id"
                :options="listSecteurs"
              />
            </b-form-group>
          </b-col>
          <!--           <b-col md="4">
            <b-form-group :label="$t('customer_information.faq.create_label_role_title')" label-cols-md="12" label-for="mots-cles">
              <v-select id="roles" v-model="form.role_ids" :placeholder="$t('customer_information.faq.create_input_role_placeholder')" multiple
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :reduce="(option) => option.id"
                :options="listRoles" />

            </b-form-group>
          </b-col> -->
          <b-col md="12">
            <b-form-group
              :label="
                $t('customer_information.faq.create_label_response_title')
              "
              label-cols-md="12"
              label-for="descripion"
            >
              <template v-slot:label>
                <span>{{
                        $t("customer_information.faq.create_label_response_title")
                      }}
                  <RequiredStar />
                </span>
              </template>
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <vue-editor
                  v-model="form.response"
                  :editor-toolbar="customToolbar"
                  :placeholder="
                    $t(
                      'customer_information.faq.create_input_response_placeholder'
                    )
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              class="d-flex flex-row"
            >
              <b-form-checkbox

                v-model="form.can_show"
              />
              Visible sur tout le site
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button
            variant="primary"
            :disabled="isCreatingProduct"
            @click="createFaq()"
          >
            <feather-icon
              v-if="isCreatingProduct == false"
              icon="SaveIcon"
            /><b-spinner
              v-if="isCreatingProduct == true"
              small
            /> &nbsp;
            {{ $t("customer_information.faq.create_form_button") }}
          </b-button>
        </div>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTags,
  BSpinner,
  BCardHeader,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import faqStoreModule from '@/store/faq'
import secteursStoreModule from '@/store/secteurs'
import rolesStoreModule from '@/store/roles'
import RequiredStar from '@/components/RequiredStar.vue'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTags,
    VueEditor,
    vSelect,
    BSpinner,
    BCardHeader,
    ValidationProvider,
    ValidationObserver,
    RequiredStar,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'faqs', module: faqStoreModule },
      { path: 'secteurs', module: secteursStoreModule },
      { path: 'roles', module: rolesStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
    }
  },

  data() {
    return {
      listSecteurs: [],
      listRoles: [],
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'link'],
      ],
      form: {
        title: null,
        faq_section_id: null,
        response: null,
        can_show: false,
        // role_ids: null
      },
      isCreatingProduct: false,
      isFetchingSecteur: false,
    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        //  await this.loadRoles()
      },
    },
  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },
  mounted() {
    this.fetchSecteurs()
  },
  methods: {
    ...mapActions('faqs', {
      action_fetchFaqSections: 'fetchFaqSections',
      action_createFaq: 'createFaq',
    }),
    ...mapActions('secteurs', {
      action_fetchSecteurs: 'fetchSecteurs',
    }),
    ...mapActions('roles', {
      action_fetchRoles: 'fetchRoles',
    }),
    async loadRoles() {
      try {
        const response = await this.action_fetchRoles()
        console.log('response::: ', response)
        if (response.status === 200) {
          this.listRoles = response.data.data
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des roles', error)
      }
    },
    fetchSecteurs() {
      this.isFetchingSecteur = true
      this.action_fetchFaqSections()
        .then(response => {
          this.isFetchingSecteur = false
          this.listSecteurs = response.data.data
          // console.log('this.formload11111: ', this.listSecteurs)
        })
        .catch(error => {
          this.isFetchingSecteur = false
        })
    },
    async createFaq() {
      await this.$refs.formRules
        .validate()
        .then(success => {
          this.isCreatingProduct = true
          console.log('this.form: ', this.form)
          this.action_createFaq(this.form)
            .then(response => {
              if (response.status === 201) {
                this.isCreatingProduct = false

                this.$router.replace({ name: 'configs-faqs-list' }).then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Création réussie',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: '',
                    },
                  })
                })
              }
            })
            .catch(error => {
              // console.log(error)
              this.isCreatingProduct = false
              // this.errored = true
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Une erreur est survenue',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: '',
                },
              })
            })
        })
        .catch(err => console.log(err))
    },
  },
}
</script>
<style lang="scss" scoped>
.invoice-preview-wrapper {
  .row.invoice-preview {
    .col-md-8 {
      max-width: 100%;
      flex-grow: 1;
    }

    .invoice-preview-card {
      .card-body:nth-of-type(2) {
        .row {
          > .col-12 {
            max-width: 50% !important;
          }

          .col-12:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
</style>
