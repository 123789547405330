import { BaseService } from './base.service'

export class FaqService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super()
  }

  // FAQ QUESTIONS

  createFaq(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/faqs', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchFaq(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/faqs', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getFaq(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/faqs/${id}`, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateStatusFaq(object) {
    const { id, data } = object
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/faqs/${id}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  showFaq(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/faqs/${id}`, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateFaq(object) {
    const { id, data } = object
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/faqs/${id}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  deleteFaq(id) {
    console.log('id :>> ', id)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`/faqs/${id}`, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  searchFaq(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/faqs/search', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // FAQ SECTIONS

  fetchFaqSections(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/faq-sections', { data })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new FaqService()
