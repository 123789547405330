<template>
    <span class="required-field" v-b-tooltip.hover.top.v-warning="'Ce champ est obligatoire.'">*</span>
</template>
<script>
import {

    VBTooltip,
} from "bootstrap-vue";
export default {
    directives: {
        "b-tooltip": VBTooltip,
    },
}
</script>
<style scoped>
.required-field {
    color: red;
    margin-left: 2px;
    /* Adjust the margin as needed */
}
</style>